<template>
  <Container>
    <TableContent>
      <thead>
        <tr>
          <th colspan="2"></th>
          <th colspan="3">{{ $t("common.rightEye") }}</th>
          <th colspan="3">{{ $t("common.leftEye") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="2" class="title__prescription">
            {{ $t("print.contactLen.glassType") }}
          </td>
          <td colspan="6" class="content__prescription font-weight-bold">
            <span>{{ typeCls }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="title__prescription">
            {{ $t("print.contactLen.parameterGlass") }}
          </td>
          <td colspan="3" class="content__prescription">
            <span>{{ clsData.od.parameterGlass.brand }}</span>
          </td>
          <td colspan="3" class="content__prescription">
            <span>{{ clsData.os.parameterGlass.brand }}</span>
          </td>
        </tr>
        <!-- Type - BC - DIA -->
        <tr>
          <td colspan="2" class="title__prescription">
            {{ $t("print.contactLen.parameter") }}
          </td>
          <td colspan="3" class="content__prescription">
            <div class="d-flex flex-column w-100 pl-4 pr-2">
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">{{ $t("print.contactLen.type") }}:</b>
                  {{ clsData.od.type }}</span
                >
              </div>
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">BC:</b>
                  {{ clsData.od.parameterGlass.bc }}</span
                >
              </div>
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">DIA:</b>
                  {{ clsData.od.parameterGlass.dia }}</span
                >
              </div>
            </div>
          </td>
          <td colspan="3" class="content__prescription">
            <div class="d-flex flex-column w-100 pl-4 pr-2">
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">{{ $t("print.contactLen.type") }}:</b>
                  {{ clsData.os.type }}</span
                >
              </div>
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">BC:</b>
                  {{ clsData.os.parameterGlass.bc }}</span
                >
              </div>
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">DIA:</b>
                  {{ clsData.os.parameterGlass.dia }}</span
                >
              </div>
            </div>
          </td>
        </tr>
        <!-- S-C-A -->
        <tr>
          <td colspan="2" class="title__prescription">
            {{ $t("print.contactLen.degreeGlass") }}
          </td>
          <td colspan="3" class="content__prescription">
            <div class="d-flex flex-column w-100 pl-4 pr-2">
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">S:</b>
                  {{ degreeSCA(clsData.od.contactGlass.s) }}</span
                >
              </div>
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">C:</b>
                  {{ degreeSCA(clsData.od.contactGlass.c) }}</span
                >
              </div>
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">A:</b>
                  {{ degreeSCA(clsData.od.contactGlass.a) }}</span
                >
              </div>
            </div>
          </td>
          <td colspan="3" class="content__prescription">
            <div class="d-flex flex-column w-100 pl-4 pr-2">
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">S:</b>
                  {{ degreeSCA(clsData.os.contactGlass.s) }}</span
                >
              </div>
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">C:</b>
                  {{ degreeSCA(clsData.os.contactGlass.c) }}</span
                >
              </div>
              <div class="d-block w-100 text-left">
                <span
                  ><b class="bold__case">A:</b>
                  {{ degreeSCA(clsData.os.contactGlass.a) }}</span
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </TableContent>
    <Footer>
      <v-row justify="space-between">
        <v-col cols="6">
          <div class="d-flex w-100 flex-column">
            <span class="note__title">{{ $t("print.contactLen.note") }}:</span>
            <span class="text__note">
              {{ clsData.ou.note }}
            </span>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="footer__staffHandler">
            <span class="footer__staffHandler__date"
              >{{ $t("print.contactLen.date") }}:
              <b class="bold__case">{{ today }}</b></span
            >
            <span class="footer__staffHandler__role">{{
              $t("print.contactLen.staffHandler")
            }}</span>
            <div class="footer__staffHandler__signal"></div>
          </div>
        </v-col>
      </v-row>
    </Footer>
  </Container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { Container, TableContent, Footer } from "./css/style";
import OptometryConclusionService from "@/services/optometryConclusion";
export default {
  name: "ContactLensPrintComponent",
  components: {
    Container,
    TableContent,
    Footer,
  },
  props: {
    print: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    today() {
      return moment().format("DD-MM-YYYY");
    },
    typeCls() {
      return _.upperFirst(this.$route.params.typeCls);
    },
  },
  data: () => ({
    clsData: {
      od: {
        parameterGlass: {
          brand: "",
          bc: "",
          dia: "",
        },
        contactGlass: {
          s: "",
          c: "",
          a: "",
        },
        type: "",
      },
      os: {
        parameterGlass: {
          brand: "",
          bc: "",
          dia: "",
        },
        contactGlass: {
          s: "",
          c: "",
          a: "",
        },
        type: "",
      },
      ou: {
        note: "",
      },
    },
  }),
  mounted() {
    this.getClsPrinting();
  },
  methods: {
    async getClsPrinting() {
      let typeCls = this.$route.params.typeCls;
      let episodeRecordID = this.$route.params.episodeRecordID;
      let result = await OptometryConclusionService.getPrintClsPrescription(
        episodeRecordID,
        typeCls
      );
      if (result.error) {
        this.$toast.error(result.message);
        return;
      }

      this.clsData = {
        od: result.od,
        os: result.os,
        ou: result.ou,
      };

      if (this.print) {
        setTimeout(() => {
          window.print();

          // setTimeout(() => {
          //   window.close();
          // }, 500);
        }, 500);
      } else {
        return;
      }
    },

    degreeSCA(value) {
      return value != "" ? `${value} D` : "";
    },
  },
};
</script>

<style>
</style>